export const team = [
  {
    src: require("./../assets/images/team/marty.jpg"),
    name: "Marty Felsenthal",
    title: "Partner",
    bio: "Marty has worked exclusively with innovative, disruptive, and rapidly growing healthcare software and services companies for the last 25 years. Marty was previously a partner at two leading venture and growth capital firms focused on this sector - Salix Ventures and HLM Venture Partners - and also worked with Madison Dearborn Partners earlier in his career. He also serves as an adviser to the California Healthcare Foundation Innovation Fund.",
    linkedin: "martinfelsenthal",
    investments: [
      "Array Behavioral Care (Board Director)",
      "OnShift (Board Director)",
      "IVX Health (Board Director)",
      "Artera (Board Director)",
      "Compass Surgical",
    ],
    prior_investments: [
      "MDLIVE (Board Director, acquired by Cigna)",
      "Teladoc (Board Director, NYSE: TDOC)",
      "Change Healthcare (Board Director, acquired by Emdeon)",
      "Aperio (Board Director, acquired by Leica Biosystems)",
      "Titan Health (Board Director, acquired by United Surgical Partners)",
      "US Renal Care (Board Director, acquired by Leonard Green)",
      "Payerpath (Board Director, acquired by Misys/Allscripts)",
      "Vantage Oncology (Board Director, acquired by McKesson)",
      "NovoLogix (Board Director, acquired by CVS Health)",
      "VeriCare (Board Director, acquired by MedOptions)",
      "ClearDATA Networks (Board Director)",
      "OnShift (Board Director, partial sale to ShiftKey)",
    ],
    education: [
      "AB, Princeton University",
      "MBA, Stanford University Graduate School of Business",
    ],
  },
  {
    src: require("./../assets/images/team/bruce.jpg"),
    name: "Bruce Crosby",
    title: "Partner",
    bio: "Bruce has spent 25+ years as a healthcare executive, entrepreneur, and venture and growth stage investor. He has helped build and invested in a number of high growth, successful healthcare software and services companies with revenues ranging from $10 million to $700 million.  Bruce's executive roles have been with Compassus, one of the largest providers of hospice and palliative care; Healthways, a pioneer and leading provider of disease and population health management; Pfizer, a global pharmaceutical company; and The Advisory Board Company (sold to Optum), one of the nation's largest health system best-practice research and business intelligence software businesses.",
    linkedin: "bruce-crosby-58267b",
    investments: [
      "Spero Health (Board Director)",
      "Three Oaks Hospice (Board Director)",
      "Ennoble Care (Board Director)",
      "Fortified Health Security (Board Director)",
      "HealthJoy (Board Observer)",
    ],
    prior_investments: [
      "Contessa Health (Board Director, acquired by Amedysis)",
      "HospitalIQ (Board Observer, acquired by LeanTaas)",
      "Accordant Health Services (acquired by AdvancePCS)",
      "National Surgical Care (acquired by Amsurg)",
      "IASIS Healthcare (acquired by TPG Capital)",
      "D2Hawkeye (acquired by Verisk Analytics)",
      "iTriage (acquired by Aetna)",
    ],
    education: [
      "AB, Princeton University",
      "MBA, The Wharton School at The University of Pennsylvania",
    ],
  },
  {
    src: require("./../assets/images/team/saurabh.jpg"),
    name: "Saurabh Bhansali",
    title: "Partner",
    bio: "Saurabh has spent his career working with innovative healthcare services and software companies. Most recently, Saurabh helped build and lead investments for Humana Health Ventures, the venture capital arm at one of the nation’s leading healthcare companies and second largest Medicare Advantage insurer. Prior to Humana, Saurabh held roles on the investing teams at HLM Venture Partners and Ferrer Freeman & Co and worked at Pfizer as part of the mergers, acquisitions, and licensing team.",
    linkedin: "s-bhansali",
    investments: [
      "Octave (Board Director)",
      "HealthJoy (Board Director)",
      "Zipari (Board Director)",
      "Headspace Health (Board Observer)",
      "Spero Health (Board Observer)",
      "Torch Dental (Board Director)",
      "Prolucent (Board Observer)",
    ],
    prior_investments: [
      "Jvion (Board Director, acquired by Lightbeam Health Solutions)",
      "Aspire Health (Board Observer, acquired by Anthem)",
      "Circulation Health (acquired by LogistiCare)",
      "Livongo Health (Board Observer, IPO - NASDAQ:LVGO)",
      "Omada Health",
    ],
    education: [
      "BA, Northwestern University",
      "MBA, The Wharton School at The University of Pennsylvania",
    ],
  },
  {
    src: require("./../assets/images/team/kristin.jpg"),
    name: "Kristin Torres Mowat",
    title: "Partner",
    bio: "Kristin has more than 15 years of successful operating experience in healthcare software and services organizations, serving as a senior executive within a Fortune 200 healthcare services company and helping to lead and scale a healthcare software company from the early stages through IPO.  Kristin previously served as SVP Corporate and Business Development for Castlight Health, leading one of the sector’s earliest innovators in transparency, engagement, and member advocacy in the areas of growth strategy, health plan relationships, and strategic partnerships. Prior to Castlight Health, Kristin was a senior executive at DaVita, where she was responsible for Corporate Strategy, founded and ran the company’s specialty EHR line of business, and led recruiting for the Leadership Development Program.  Kristin has extensive experience serving on boards and advising healthcare and technology companies through critical growth periods, fundraising, and M&A transactions.  Kristin began her career at Bain & Co. and is from Mexico City.",
    linkedin: "kristintm",
    investments: [
      "Women’s Health USA (Board Director) ",
      "In Vitro Sciences (Board Director) ",
      "Greater Good Health (Board Member) ",
    ],
    education: [
      "BA, Stanford University",
      "MBA, Stanford University Graduate School of Business",
      "JD, Stanford Law School ",
    ],
  },
  {
    src: require("./../assets/images/team/grant.jpg"),
    name: "Grant Blevins",
    title: "Partner",
    bio: "Grant has spent his entire career investing exclusively in innovative healthcare companies. Prior to joining Health Velocity Capital, Grant was a healthcare investor with Clayton Associates, Nashville’s oldest venture capital firm, where he co-led deal sourcing. He sourced notable investments in RubiconMD, Shiftwizard (acquired by Health Stream [NASDAQ: HSTM]), and HealthiPASS (acquired by Sphere).",
    linkedin: "grantblevins",
    investments: [
      "Array Behavioral Care (Board Observer)",
      "IVX Health (Board Observer)",
      "Artera (Board Observer)",
      "Clever Care (Board Member)",
      "Fortified Health Security (Board Member)",
      "Prolucent Health (Board Member)",
      "OnShift",
      "Zipari",
      "Contessa",
      "Torch Dental",
    ],
    prior_investments: [
      "Lemonaid Health (Board Observer, acquired by 23andMe)",
      "MDLIVE (Acquired by Cigna)",
      "Contessa (Acquired by Amedysis",
    ],
    education: ["BBA, Belmont University"],
  },
  {
    src: require("./../assets/images/team/william.jpg"),
    name: "William Tan",
    title: "Senior Associate",
    original: true,
    bio: "William has spent his entire career advising innovative technology and healthcare companies. Prior to joining Health Velocity Capital, William was an investment banker at William Blair & Company advising software companies on transformative buy-side and sell-side M&A transactions. Prior to Blair, William worked at JMP Securities advising high growth technology and healthcare companies on numerous M&A and private placement transactions.",
    linkedin: "william-tan-15583497",
    education: ["BBA, University of Kentucky"],
  },
  {
    src: require("./../assets/images/team/christian.jpg"),
    name: "Christian Pierce",
    title: "Senior Associate",
    original: true,
    bio: "Christian has spent his career working at early-stage startups and advising high-growth healthcare services and technology businesses. Prior to joining Health Velocity Capital, Christian was the first hire at HomeCloud, a disruptive, venture-backed Proptech startup. Prior to HomeCloud, Christian was an investment banker at Cain Brothers and Wells Fargo Securities, advising healthcare companies on M&A and capital-raising transactions.",
    linkedin: "christian-pierce-62ab5896",
  },
  {
    src: require("./../assets/images/team/ken.jpg"),
    name: "Ken Goodhue",
    title: "VP of Finance",
    original: true,
    bio: "Ken has spent his career working with corporate and private equity clients to create value in the healthcare sector. Prior to joining Health Velocity Capital, Ken led diligence efforts as a Senior Manager at PwC for transactions ranging from $20 million to $5 billion. His experience consists of evaluating opportunities across the healthcare sector including: behavioral health, software, primary care, urgent care, and health plans.",
    education: [
      "BBA in Accounting, MS in Finance, Texas A&M University - Mays Business School",
    ],
    linkedin: "kennethggoodhue",
  },
  {
    src: require("./../assets/images/team/julie.jpg"),
    name: "Julie Foster",
    original: true,
    title: "Chief Administrative Officer",
    bio: "Julie is the administrator for Health Velocity Capital and is responsible for making our trains run on time.  She has been working in the venture capital industry since 1999 at firms including Salix Ventures, De Novo Ventures, and HLM Venture Partners.",
  },
  {
    src: require("./../assets/images/team/morgan.jpg"),
    name: "Morgan Putty",
    original: true,
    title: "Executive Assistant",
  },
];

export const advisors = [
  {
    src: require("./../assets/images/team/sally.jpg"),
    name: "Sally Welborn",
    title: "Founding Advisory Board Member",
    bio: "Sally currently advises health care organizations, entrepreneurs, and others in the health care and employee benefits arenas, and works extensively as the Executive Advisor to the Employer Health Innovation Roundtable. Prior to her current activities, she was most recently the Senior Vice President of Global Benefits for Walmart Stores, Inc. where she was responsible for overseeing the global benefits programs for Walmart’s more than 2.2 million associates and their families in twenty-eight countries. Prior to joining Walmart, she was senior vice president, Corporate Benefits for Wells Fargo & Company.  Before that, her career includes consulting with large employers in all areas of benefits strategy and health care optimization when she was with HCIA, Inc. and William M. Mercer, Inc. She also worked at Aetna as a relationship manager and at Union Bank as the Vice President of Benefits.<br /><br />Sally currently serves on the Board of Directors of Truveris, Inc., the Leapfrog Group, and Vivante Health.  She also serves on the Advisory Board for the Penn Center for Health Incentives and Behavioral Economics. ",
  },
  {
    src: require("./../assets/images/team/mark.jpg"),
    name: "Mark Smith, MD",
    original: true,
    title: "Founding Advisory Board Member",
    bio: "Mark Smith, MD serves as Co-chair of the Health Care Payment Learning & Action Network’s Guiding Committee. He is Visiting Professor, University of California at Berkeley, and Clinical Professor of Medicine, University of California at San Francisco.  He serves on the board of Teladoc (NYSE: TDOC) and Phreesia (NYSE: PHR). He was the Founding President and former Chief Executive Officer of the California HealthCare Foundation (CHCF).  Previously, Mark was Executive Vice President at the Henry J. Kaiser Family Foundation where he oversaw programs in HIV, reproductive health, and the health care marketplace. Prior to that, he was a faculty member at the Johns Hopkins Schools of Medicine and of Public Health. Dr. Smith was elected a member of the Institute of Medicine (IOM) in 2001 and chaired the IOM’s Committee on the Learning Healthcare System, which produced the 2012 report, Best Care at Lower Cost.  He has served on the board of the National Business Group on Health, the performance measurement committee of the National Committee for Quality Assurance, and the editorial board of the Annals of Internal Medicine.",
  },
  {
    src: require("./../assets/images/team/scott.png"),
    name: "Scott Powder",
    title: "Founding Advisory Board Member",
    bio: "Scott Powder is responsible for strategy, innovation and long-range strategic planning and business development activities, including mergers, acquisitions and strategic partnerships for Advocate Aurora Health, one of the nation’s 10 largest not-for-profit health systems with more than 500 sites of care, 8,100 physicians, and 70,000 team members.  A national strategic thought leader, Scott has served at Advocate Health Care for 24 years and has held a number of business development and strategy positions during his tenure, including Vice President Business Development for Lutheran General Hospital, Vice President Business Development for Advocate Medical Group, and Vice President System Strategy and Development.<br /><br />He currently serves on the Board of Directors for Advocate Physician Partners, Advocate Home Products, and Advocate’s joint venture with SCA.  Scott also serves as a strategic advisor to Leidos, a Washington DC-based military and health IT firm.  Scott earned his master’s degree from the Kellogg Graduate School of Management at Northwestern University and his bachelor’s degree in International Relations from Michigan State University.",
  },
  {
    src: require("./../assets/images/team/kent.jpg"),
    name: "Kent Marquardt",
    title: "Founding Advisory Board Member",
    bio: "Kent is the former Executive Vice President and Chief Financial Officer of Premera Blue Cross, where he was responsible for finance, corporate informatics, healthcare economics, innovation, and strategic investments. As part of these efforts, he identified and sponsored a number of important and successful healthcare innovators and played a key role as an early customer at companies such as Landmark Health, Teladoc, and MOBE. He previously served as a partner with KPMG Peat Marwick, as Chief Financial Officer for PriMed Management Consulting (Hill Physician Management Company), as CEO of American Dental Specialists, and Chief Operating Officer and Chief Financial Officer of the western operations for MedPartners, the nation's then largest publicly held physician practice management company. Kent is a board member of Prealize, Brook and Project Connect and is an Advisory Board member for Health Velocity Capital and Echo Health Ventures. He is also an advisor to Vim. He lives in Walla Walla, Washington and is an owner in Balboa Winery and Stoney Vine vineyard.",
  },
  {
    src: require("./../assets/images/team/doug.jpg"),
    name: "Doug Ghertner",
    title: "Founding Advisory Board Member",
    bio: "Doug Ghertner is the CEO of IVX Health and former Chief Sales Officer and President, Engagement Solutions, of Emdeon (now Change Healthcare).  Previously, he was CEO of Change Healthcare prior to its acquisition by Emdeon.  Change Healthcare was a leading provider of member engagement and cost transparency solutions sold to health plans and large self-insured employers.  During his tenure, Change Healthcare increased the number of individuals who received its services from 20,000 to more than 15 million.  Prior to joining Change Healthcare as CEO, Doug served in a variety of leadership roles with CVS, including SVP of Client Solutions.",
  },
  {
    src: require("./../assets/images/team/brad.jpg"),
    name: "Brad Fluegel",
    original: true,
    title: "Founding Advisory Board Member",
    bio: "Brad Fluegel was most recently Senior Vice President, Chief Healthcare Commercial Market Development Officer for Walgreens. Brad was responsible for all commercial healthcare activities, including sales and contracting, biopharma relationships, retail clinics, clinical affairs, new service development and market planning.  Previously, he was Chief Strategy and Business Development Officer for Walgreens, responsible for corporate strategy and business development. Prior to Walgreens, he was Chief Strategy and External Affairs officer of Anthem, where he was responsible for long-term strategic planning, government affairs, corporate communications including public relations, corporate marketing, corporate development, international expansion, innovation and new business ventures. Prior to Anthem, Brad was Senior Vice President of National Accounts and Vice President, Enterprise Strategy at Aetna. Brad currently serves on the Board of Directors of Metropolitan Jewish Health System in New York City, Performant Financial Corporation, Fitbit, Premera Blue Cross, Alight Solutions, and AdhereHealth. Brad earned a master’s degree in public policy from Harvard University’s Kennedy School of Government and a Bachelor of Arts in Business Administration from the University of Washington.  He also serves as a lecturer at the University of Pennsylvania’s Wharton School of Business.",
  },
  {
    src: require("./../assets/images/team/karen.jpg"),
    name: "Karen Murphy, PhD, RN",
    original: true,
    title: "Founding Advisory Board Member",
    bio: "Karen currently serves as a lead developing the value-based platform in Risant Health. Dr. Karen Murphy is formerly the Executive Vice President, Chief Innovation and Digital Transformation Officer and Founding Director of the Steele Institute for Health Innovation at Geisinger. Before joining Geisinger, Dr. Murphy served as Pennsylvania’s Secretary of Health addressing the most significant health issues facing the state, including the opioid epidemic. Prior to her role as Secretary, Dr. Murphy served as director of the State Innovation Models Initiative at the Centers for Medicare and Medicaid Services leading a $990 million CMS investment designed to accelerate health care innovation across the United States. She previously served as President and Chief Executive Officer of the Moses Taylor Health Care System in Scranton, and as Founder and Chief Executive Officer of Physicians Health Alliance, Inc., an integrated medical group practice within Moses Taylor. Dr. Murphy earned her Doctor of Philosophy in business administration from the Temple University Fox School of Business. She holds a Master of Business Administration from Marywood University, a Bachelor of Science in liberal arts from the University of Scranton, and a diploma in nursing from the Scranton State Hospital School of Nursing. She also serves as a clinical faculty member at Geisinger Commonwealth School of Medicine and as an Associate Faculty member in the Department of Health Policy and Management at Johns Hopkins Bloomberg School of Public Health. She also is a member of the Partners Digital Advisory Board and the University of Pennsylvania LDI Advisory Panel.",
  },
  {
    src: require("./../assets/images/team/rick.jpg"),
    name: "Rick Jelinek",
    original: true,
    title: "Founding Advisory Board Member",
    bio: "Rick Jelinek most recently served as Executive Vice President at CVS Health, where he was responsible for the Company’s Enterprise Modernization initiatives and the Integration activities associated with the Aetna / CVS merger.  Rick helped create, position and execute the $80B deal between the two companies. Prior to the transaction, he ran Enterprise Strategy at Aetna as well as oversaw Aetna’s Domestic Markets which included a $45B P&L covering commercial and Medicare field and local markets operations, National Accounts and the Company’s National Network Operations. Rick also spent 19 years at UnitedHealth Group.  He served as CEO of OptumHealth which included OptumCare provider delivery businesses, Optum Financial, Optum Behavioral Health and Optum clinical assets.  Other assignments while at UHG included EVP and CEO of United’s Emerging Businesses Group and its Medicare, Senior & Retiree Services and Medicaid (UHC Community & State) business units. Before joining UHG, Jelinek held successive management roles at Henry Ford Health System, The Center for Healthcare Information Management and North Shore University Health System. Rick is a member of YPO, a board member of HealthEdge and a founding advisory board member for the Griffith Leadership Center at the University of Michigan School of Public Health. He previously served on the board of directors of Sutter Health, Cotiviti, the Minnesota Children’s Museum, The Long Term Care Group and Redbrick Health. He received his master’s degree in health services administration and an MBA from the University of Michigan, as well as a bachelor’s degree in business administration from the University of Southern California.",
  },
  {
    src: require("./../assets/images/team/dena.jpg"),
    name: "Dena Bravata M.D., M.S.",
    title: "Founding Advisory Board Member",
    bio: "Dr. Dena Bravata is a physician, healthcare entrepreneur, and executive.  Dr. Bravata was the co-founder of Lyra Health where she also served as the Chief Medical Officer from 2015 to 2016. She was the Chief Medical Officer and Head of Products at Castlight Health from 2009 through 2014 and again from 2019 to 2022 (formerly NYSE:CSLT). Dr. Bravata was a practicing internist for 16 years in her own private practice and an attending physician at Stanford University and the Palo Alto VA. As a nationally recognized health services researcher, Dr. Bravata has published widely on the clinical effectiveness of a variety of key health technology, clinical and public health topics.  She is a founding member of the Aspen Institute Healthcare Strategy Group, was an assistant public health officer for Santa Clara County, and serves on the board of several health technology and services companies.  In 2021, Dr. Bravata received a national service award from the White House and CDC for her work on the pandemic response and vaccines.gov.  Dr. Bravata has an undergraduate degree from Yale University, an M.D. from Columbia University, and a M.S. in health policy from Stanford University.  She completed her Internal Medicine Residency and Ambulatory Care Fellowship at Stanford University.",
  },
];

export const pastAdvisors = [
  {
    src: require("./../assets/images/team/david_2.jpg"),
    name: "David Shulkin, MD",
    title: "Founding Advisory Board Member",
    bio: "Dr. David J. Shulkin was the Ninth Secretary of the US Department of Veterans Affairs having been appointed by President Trump.  Secretary Shulkin previously served as Under Secretary for Health, having been appointed by President Obama and confirmed twice unanimously by the US Senate.  As Secretary, Dr. Shulkin represented the 21 million American veterans and was responsible for the nation’s largest integrated health care system with over 1,200 sites of care, serving over 9 million Veterans. Prior to coming to VA, Secretary Shulkin was a widely respected healthcare executive having served as chief executive of leading hospitals and health systems including Beth Israel in New York City and Morristown Medical Center in Northern NJ. As an entrepreneur, Secretary Shulkin founded and served as the Chairman and CEO of DoctorQuality and has served on boards of managed care companies, technology companies, and health care organizations.",
  },
  {
    src: require("./../assets/images/team/ben.jpg"),
    name: "Ben Leedle",
    title: "Founding Advisory Board Member",
    bio: "Ben Leedle served as CEO of Healthways (NASDAQ:  HWAY) from 2003 until 2015.  During his tenure as CEO, Healthways increased revenue from $165 million to $770 million and became the leading independent disease management, population health management, consumer engagement, and senior-focused consumer services company in healthcare.  Ben joined Healthways in 1985 initially supporting early models for advancing population health for people with diabetes. Over his 30-year career with the company, he rose in the organization as a leader and manager of business development, product management, and operations – serving as the company’s Chief Operating Officer - before being elected President & Chief Executive Officer, and Board Director of Healthways in 2003.",
  },
  {
    src: require("./../assets/images/team/frank.jpg"),
    name: "Frank Williams",
    title: "Founding Advisory Board Member",
    bio: "Frank Williams is the CEO and Co-Founder of Evolent Health (NYSE: EVH). Evolent helps physicians and health systems achieve superior performance through value-based care transformation with the ultimate objective of dramatically improving the health of the populations that it serves.  The company currently partners with leading health systems in over 35\r\nmarkets, manages over 3.5M lives under value-based arrangements, and employs over 3,700 professionals. \r\n\r\nPrior to Evolent, Frank also served as Chairman and CEO of The Advisory Board Company (NASDAQ: ABCO), a global research and technology firm that serves over 5,000 organizations across the Health care and Higher Education sectors. During Frank's tenure as both its\u2019 Chairman and CEO, The Advisory Board completed an initial public offering and grew to $450M+ in annual revenues with over 2,500 employees and $2B+ in market capitalization.\r\n\r\nAlong with the Evolent Health board, he currently serves on the boards of Syapse, Business Talent Group, and Peer Health Exchange and as an Advisor to TPG\u2019s Growth and Rise Funds.  A native Californian, Frank received a BA in Political Economy from UC Berkeley and an MBA from Harvard Business School.\r\n",
  },
  {
    src: require("./../assets/images/team/david.jpg"),
    name: "David Felsenthal",
    title: "Founding Advisory Board Member",
    bio: "David Felsenthal is currently CEO of EAB, a portfolio company of Vista Equity Partners, and was previously CEO of The Advisory Board Company (NASDAQ:  ABCO) a global research, technology, and consulting firm that serves over 4,100 leading organizations across health care and higher education.  Having first joined the firm in 1992, he previously served as The Advisory Board Company’s chief operating officer and chief financial officer and has also held roles in both new product development and research.  He formerly served as director of business development and special assistant to the CEO/CFO at Vivra Specialty Partners, a private health care services and technology firm.",
  },
  {
    src: require("./../assets/images/team/mick.png"),
    name: "Mick Murray",
    original: true,
    title: "Founding Advisory Board Member",
    bio: "Mick currently holds the role of senior business advisor at Blue Shield of California where he is advising on strategy and operational execution optimization.  He held the position of senior vice president and chief financial officer at Blue Shield of California between 2013 and 2018 and was responsible for the\r\ncompany's financial strategy  and operations including capital planning, budgeting, forecasting and financial accounting and reporting. He also oversaw the actuarial, corporate development, real estate and procurement functions within the company. Prior to joining Blue Shield of California he held various senior finance roles in a number of global technology companies. He served as chief financial officer at Hitachi Global Storage and chief accounting officer at Yahoo, where he was responsible for  domestic and international finance operations. Previously, Mick held a variety of corporate finance positions at Sun Microsystems Inc., which included assignments in Hong Kong; Sydney, Australia; and Broomfield, Colorado.\r\n\r\nMick is a native of Ireland and holds masters and bachelors degrees from National University of Ireland - Dublin. He started his career with Price Waterhouse Dublin and qualified as a member of the institute of Chartered Accountants in Ireland.",
  },
  {
    src: require("./../assets/images/team/lew.png"),
    name: "Lew Trowbridge",
    original: true,
    title: "Founding Advisory Board Member",
    bio: 'Lew Trowbridge is the chairman and CEO of Canyon Road Advisors, LLC (CRA), a Santa Fe, NM based investment/advisory firm focused on helping healthcare and financial services companies find ways to use innovation to drive competitive advantage. Lew retired as the President and COO of Blue Cross Blue Shield of Nebraska (BCBSNE) in 2016. Prior to being named president, he served as the Executive Vice President, CFO and Treasurer of the company. Under his leadership, BCBSNE developed one of the most extensive and financially successful private equity portfolios within the Blue Cross Blue Shield system. Lew was also the co-founder of the Blue Investment Collaborative, a coalition of 10 like-minded Blue Plans that meet regularly to discuss collaborative private equity investment opportunities and common operating issues. He is also the past chairman of International Plan Solutions, LLP, a joint venture formed by 14 Blue Plans to focus on international health-related investments. Lew was a partner with KPMG for 22 years, and between his time at KPMG and Nebraska Blue, he was also the CEO of two Omaha based private equity "roll ups". In addition to his Advisory Board role with Health Velocity Capital, Lew currently serves as an advisor, investor and/or board member for several health and innovation related companies and funds, and he is also a board member and audit committee chair of a billion-dollar regional bank in Omaha. Past board positions also include Think Whole Person Primary Care, LLC, a first-of-its-kind, integrated care delivery enterprise with 46,000 patients and more than 25 independent primary care physicians in Omaha; TriWest, a large Blue owned entity serving the VA and TriCare spaces; and InstaMed, one of the largest payment processing companies in healthcare, which was sold to JP Morgan in 2019. Lew earned both BBA and MBA degrees from the University of Cincinnati, and he has been a Certified Public Accountant for over 40 years.',
  },
  {
    src: require("./../assets/images/team/mike.jpg"),
    name: "Mike Weissel",
    original: true,
    title: "Founding Advisory Board Member",
    bio: "Mike is the CEO at SecureSeniorConnections, a digitally enabled solution to help Seniors with issues of loneliness and social isolation.  Prior to that he worked for Optum as a member of the company's Executive Leadership Team, which had responsibility for Optum’s overall business performance. From 2016-2019 he was Group Executive Vice President and Head of Corporate Strategy, Product, and Business Partnerships helping to set the strategic direction of the enterprise while also managing a portfolio of over 400 Optum products. From 2013-2016 Mike was the CEO of Optum’s Consumer Solutions Group with complete P&L responsibility for a multibillion-dollar business unit focused on Optum’s Population Health, Behavioral Health, Financial Services and Distribution Services businesses. Prior to joining Optum, Mike was a Managing Partner at Oliver Wyman for 17 years, a leading international management consulting firm. He was a member of the senior leadership team and Executive Sponsor of Diversity & Inclusion for the firm. In 2007 he founded Oliver Wyman’s Health & Life Sciences practice and served as the global Managing Partner with full P&L responsibility for that practice until 2013. Prior to Oliver Wyman, he worked as a Certified Public Accountant and Associate Partner at Price Waterhouse.  Mike holds an MBA from Duke University and a BA in accounting from UMASS, Amhert.",
  },
];
